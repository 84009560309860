import imageCompression from 'browser-image-compression'

export default async (file: any): Promise<File> => {
  if (! file.type.startsWith('image')) return file

  let compressedFile: any = await imageCompression(file, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1440,
    initialQuality: 0.4,
    useWebWorker: true
  })

  return new File([compressedFile], file.name, { type: file.type })
}
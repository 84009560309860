






















import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import _ from 'lodash'
import compressFile from '@/shared/helpers/compress-file.helper'

@Component
export default class FileField extends AbstractField {
  url: string|null = null

  mounted() {
    this.loadImageUrl();

    this.$watch('fullKey', () => this.loadImageUrl());
  }

  loadImageUrl() {
    const value = _.get(this.form.data, this.fullKey)
    if (value instanceof File) this.readFile(value)
    else if (value) this.url = value.url
    else this.url = null
  }

  async changeFile(value: any) {
    this.value = null
    this.url = null
    this.form.setLoading(true)
    if (value && ! this.field.meta.multiple) {
      value = await compressFile(value)

      this.readFile(value)
    }
    this.form.setLoading(false)
    this.value = value
    this.removeError()
  }

  readFile(file: File) {
    const reader = new FileReader()

    reader.onload = (e: any) => {
      this.url = e.target.result
    }

    reader.readAsDataURL(file)
  }
}
